// TODO: TRANSLATION PATTERN
// TODO: REFACTORING
import React, { useState } from 'react';
import * as Styled from './SectionSharedFlightCard.style';

import { translate as t } from '@utils/translate';

const SectionSharedFlightCard = ({ data }) => {
  const [isHover, setIsHover] = useState(false);

  const handleHover = hover => {
    setIsHover(hover);
  };

  const handleClick = data => {
    console.log('');
  };

  const TEXTS = {
    STARTING_AT: t('startingAt'),
  };
  return (
    <Styled.CardWrapper
      href={data.link}
      onClick={e => handleClick(e)}
      onDragStart={e => e.preventDefault()}
    >
      <Styled.Card
        onMouseEnter={() => handleHover(true)}
        onMouseLeave={() => handleHover(false)}
      >
        <Styled.CardImageWrapper isHover={isHover}>
          <Styled.Image
            src={data.image}
            alt={data.imageAlt}
            isHover={isHover}
          />

          <Styled.InfoWrapper isHover={isHover}>
            <Styled.CardTexts isHover={isHover}>
              <Styled.PriceLabel>{TEXTS.STARTING_AT}</Styled.PriceLabel>
              <Styled.PriceValue>{data.price}</Styled.PriceValue>
            </Styled.CardTexts>

            <Styled.SubtitleHidden isHover={isHover}>
              {data.subtitle}
            </Styled.SubtitleHidden>
          </Styled.InfoWrapper>
        </Styled.CardImageWrapper>

        <Styled.Subtitle>{data.subtitle}</Styled.Subtitle>
        <Styled.Title>{data.title}</Styled.Title>
      </Styled.Card>
    </Styled.CardWrapper>
  );
};

export default SectionSharedFlightCard;

// TODO: CHECK USE
// TODO: REFACTORING
import React, { useState } from 'react';
import { navigate } from 'gatsby';
import { Link } from 'gatsby';
import Style from './style.module.css';
import { translate as t } from '../../utils/translate';
import './style.css';

// Utils
import { gtmDispatcher } from '@utils/gtm.helper';

const HomeWeeklyFlightsDetails = ({
  title,
  sectionType,
  originCity,
  destinationCity,
  description,
  routeTitle,
  image,
  imageAlt,
  date,
  cardPrice,
  link,
  fly,
}) => {
  const [hoverCard, setHoverCard] = useState('');

  const TEXTS = {
    STARTING_AT: t('startingAt'),
    NEXT_FLIGHT: t('nextFlight'),
    DIRECT: t('direct'),
  };

  const handleNavigate = link => {
    gtmDispatcher('search', {
      event_category: 'feed_search',
      event_action: 'click',
      event_label: sectionType,
      value: {
        origin: originCity,
        destination: destinationCity,
        nextFlight: date,
        minPrice: cardPrice,
        link,
      },
    });

    navigate(link);
  };

  return (
    <section
      className={[Style.section, 'homeWeeklyFlightsDetails', 'container'].join(
        ' '
      )}
    >
      <div className={Style.sectionInfos}>
        <h2 className={Style.title}>{title}</h2>
        <p className={Style.description}>{description}</p>
        <p className={Style.routeTitle}>{routeTitle}</p>
        <div className={[Style.dateWraper, hoverCard].join(' ')}>
          <p className={Style.date}>{date}</p>
        </div>
      </div>
      <div
        className={Style.card}
        onMouseEnter={() => setHoverCard('dataWrapperHover')}
        onMouseLeave={() => setHoverCard('')}
      >
        <div className={Style.link} onClick={() => handleNavigate(link)}>
          <img src={image} alt={imageAlt} className={Style.cardImage} />
          <div className={Style.cardMeta}>
            <p className={Style.cardText}>{TEXTS.STARTING_AT}</p>
            <p className={Style.cardPrice}>{cardPrice}</p>
          </div>
        </div>
      </div>
      <div className={Style.fly}>
        <p className={Style.flyText}>{TEXTS.NEXT_FLIGHT}</p>
        <Link to={fly.link} className={Style.link}>
          <div className={Style.cardFly}>
            {!fly.aircraftImage ? (
              <img
                src={fly.modelImage}
                alt={fly.imageModelAlt}
                className={Style.flyImage}
              />
            ) : (
              <img
                src={fly.aircraftImage}
                alt={fly.imageAlt}
                className={Style.flyImage}
              />
            )}
            <div className={Style.flyInfos}>
              <div className={Style.flyMeta}>
                <div className={Style.leave}>
                  <p className={Style.time}>{fly.departure}</p>
                  <div className={Style.departureWrapper}>
                    <p className={Style.airportHint}>{fly.departureHint}</p>
                    <span className={Style.showAirportButton}></span>
                    <div className={Style.airportPopupDeparture}>
                      <p className={Style.airportPopupName}>
                        {fly.locatDeparture}
                      </p>
                    </div>
                  </div>
                </div>

                <div className={Style.path}>
                  <p className={Style.pathDescription}>{TEXTS.DIRECT}</p>
                  <span className={Style.pathIlustration}></span>
                  <p className={Style.pathDuration}>{fly.duration}</p>
                </div>

                <div className={Style.arrival}>
                  <p className={Style.time}>{fly.arrival}</p>
                  <div className={Style.arrivalWrapper}>
                    <span className={Style.showAirportButton}></span>
                    <p className={Style.airportHint}>{fly.arrivalHint}</p>
                    <div className={Style.airportPopupArrival}>
                      <p className={Style.airportPopupName}>
                        {fly.locatArrival}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className={Style.flyPriceWraper}>
                <p className={Style.flyPriceText}>{TEXTS.STARTING_AT}</p>
                <p className={Style.flyPrice}>{fly.price}</p>
              </div>
            </div>
          </div>
        </Link>
      </div>
    </section>
  );
};

export default HomeWeeklyFlightsDetails;

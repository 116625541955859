import React from 'react';
import * as Styled from './FeedSectionGroupTitle.style';

const FeedSectionGroupTitle = ({ title, show = false }) => {
  if (!show) return null;

  return <Styled.Title>{title}</Styled.Title>;
};

export default FeedSectionGroupTitle;

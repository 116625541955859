// react/third-part modules
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { useParams } from '@reach/router';

// component modules
import Layout from '@components/layout';
import SEO from '@components/seo';
import HomeScheduling from '@components/HomeScheduling';
import HomeHero from '@components/HomeHero';
import SectionRemark from '@components/SectionRemark';
import HomeWeeklyFlightsDetails from '@components/HomeWeeklyFlightsDetails';
import SpinningWheel from '@components/SpinningWheel';
import FeedSectionGroupTitle from '@components/FeedSectionGroupTitle';
import SectionContainer from '@components/ContainerComponents/SectionContainer';
import SliderContainer from '@components/ContainerComponents/SliderContainer';
import CustomCharterOfferCard from '@components/Cards/CustomCharterOfferCard';
import SectionSharedFlightCard from '@components/Cards/SectionSharedFlightCard';
import HomeBanner from '@components/HomeBanner';

// contexts/hooks/others
import {
  isValidCustomOfferItem,
  mapSectionSharedFlightsItem,
  mapCustomOffersItem,
} from '@utils/feedHelper';
import { getHomeSections } from '@utils/functions';
import { getFlightSearchUrl } from '@utils/search';
import { apiHost } from '@utils/constants';
import { formatCurrency } from '@utils/currency';
import useRequest from '@utils/useRequest';
import { translate as t, useCurrentLang } from '@utils/translate';

import AircraftCarbonImage from '@images/aircraft-banner-carbon.png';
import AircraftSaleBanner from '@images/aircraft-sale-banner.jpg';

const mapsharedFlightsDetailsSection = (section, currencyCode) => {
  const sectionItems = section.items[0];
  const origin = sectionItems.nextFlight.originSpot;
  const destination = sectionItems.nextFlight.destinationSpot;
  const departureDatetime = new Date(sectionItems.nextFlight.departureDatetime);
  const arrivalDatetime = new Date(sectionItems.nextFlight.arrivalDatetime);
  const estimatedHour = Math.floor(sectionItems.nextFlight.estimatedTime / 60);
  const estimatedMinutes =
    sectionItems.nextFlight.estimatedTime - estimatedHour * 60;

  return {
    title: section.title,
    sectionType: section.type,
    originCity: sectionItems.originCity.name,
    destinationCity: sectionItems.destinationCity.name,
    description: section.subtitle,
    date: sectionItems.subtitle,
    routeTitle: sectionItems.title,
    image: sectionItems.imageUrl,
    imageAlt: sectionItems.destinationCity.name,
    cardPrice: formatCurrency({
      value: sectionItems.startingPrice,
      currency: currencyCode,
    }),
    link: getFlightSearchUrl(sectionItems, 'flights'),
    fly: {
      aircraftImage: sectionItems.nextFlight.aircraft.highlightImageUrl,
      imageAlt: sectionItems.nextFlight.aircraft.name,
      modelImage: sectionItems.nextFlight.aircraft.model.highlightImageUrl,
      imageModelAlt: sectionItems.nextFlight.aircraft.model.name,
      departure:
        departureDatetime.getHours() +
        ':' +
        (departureDatetime.getMinutes() < 10 ? '0' : '') +
        departureDatetime.getMinutes(),
      arrival:
        arrivalDatetime.getHours() +
        ':' +
        (arrivalDatetime.getMinutes() < 10 ? '0' : '') +
        arrivalDatetime.getMinutes(),
      duration:
        (estimatedHour ? estimatedHour + 'h' : '') +
        (estimatedMinutes ? estimatedMinutes + 'min' : ''),
      departureHint: origin.airport
        ? origin.airport.acronym
        : origin.helipad.acronym,
      arrivalHint: destination.airport
        ? destination.airport.acronym
        : destination.helipad.acronym,
      locatDeparture: sectionItems.nextFlight.originSpot.name,
      locatArrival: sectionItems.nextFlight.destinationSpot.name,
      price: formatCurrency({
        value: sectionItems.startingPrice,
        currency: currencyCode,
      }),
      link: getFlightSearchUrl(sectionItems, 'flights'),
    },
  };
};

const getSectionsProps = data => ({
  hero: {
    titleColor: data.wordpressAcfPages.acf.home_hero.home_hero_title,
    title: data.wordpressAcfPages.acf.home_hero.home_hero_subtitle,
    heroImage: data.wordpressAcfPages.acf.home_hero.home_hero_background,
    heroImageAlt:
      data.wordpressAcfPages.acf.home_hero.home_hero_background.alt_text,
  },
  scheduling: {
    cards: data.wordpressAcfPages.acf.home_section_differentials.home_differentials.map(
      item => {
        return {
          imagem: item.home_differential_image,
          imagemAlt: item.home_differential_image.alt_text,
          title: item.home_differential_title,
          subTitle: item.home_differential_subtitle,
          text: item.home_differential_description,
          link: item.home_differential_link,
        };
      }
    ),
  },
  about: {
    image: data.wordpressAcfPages.acf.home_section_about.home_about_background,
    imageAlt:
      data.wordpressAcfPages.acf.home_section_about.home_about_background
        .alt_text,
    link: data.wordpressAcfPages.acf.home_section_about.home_about_link,
    title: data.wordpressAcfPages.acf.home_section_about.home_about_title,
  },
  remarks: {
    title: '',
    remarks: data.wordpressAcfPages.acf.home_section_big_numbers.home_big_numbers.map(
      item => {
        return {
          number: item.home_big_number,
          text: item.home_big_number_description,
        };
      }
    ),
  },
});

const Feed = ({ data, location }) => {
  const TEXTS = {
    SHARED_FLIGHTS: t('sharedFlightsSection'),
    ROUTE_SHARED_FLIGHTS: t('routeSharedFlights'),
    POOL_FLIGHTS: t('poolFlightsSection'),
    CUSTOM_OFFERS: t('customOffersSection'),
    AIRCRAFT_SALES_BANNER_TITLE: t('aircraftSalesBannerTitle'),
    AIRCRAFT_SALES_BANNER_DESCRIPTION: t('aircraftSalesBannerDescription'),
    AIRCRAFT_CARBON_BANNER_TITLE: t('aircraftCarbonBannerTitle'),
    AIRCRAFT_CARBON_BANNER_DESCRIPTION: t('aircraftCarbonBannerDescription'),
    KNOW_MORE: t('knowMore'),
  };
  const urlParams = useParams();
  const [customOffers, setCustomOffers] = useState(null);
  const [sectionSharedFlights, setSectionSharedFlights] = useState(null);
  const [feedCards, setFeedCards] = useState(null);
  const currency = useSelector(state => state.general.currency);
  const [status, setStatus] = useState('Loading');
  const [dataResponse, setDataResponse] = useState(null);
  const sectionsProps = getSectionsProps(data);
  const lang = useCurrentLang();
  const { wordpressPage: page } = data;
  const translations = [
    { path: '/en', polylang_current_lang: 'en_US' },
    { path: '/es', polylang_current_lang: 'es_ES' },
    { path: '/', polylang_current_lang: 'pt_BR' },
    // { path: '/it', polylang_current_lang: 'it_IT' },
  ];

  const carbonBlogLink = {
    pt_BR:
      'https://flyflapper.com/stories/pt-br/voo-carbono-neutro-com-a-flapper/',
    en_US:
      'https://flyflapper.com/stories/flapper-neutralizes-carbon-emissions-of-private-flights/',
    es_ES:
      'https://flyflapper.com/stories/es/vuela-carbono-neutral-con-flapper/',
  };

  const fetchData = async url => {
    const { data: response } = await useRequest({
      url: url,
      context: 'feed-sections',
      useSession: false,
    });

    if (response.result.status === 0) {
      setStatus('Loaded');
      setDataResponse(response);
    } else if (response.msg.status === 'canceled') {
      setStatus('Loading');
    } else {
      setStatus('Error');
    }
  };

  useEffect(() => {
    setStatus('Loading');
    const home_feed_api = `${apiHost}/api/v1/feed/get?currency=${currency.code}`;
    //const searchParams = new URLSearchParams(window.location.search);
    let countrySlug = urlParams.country;
    countrySlug = countrySlug ? `&slug=${countrySlug}` : '';

    fetchData(home_feed_api + countrySlug);
  }, [currency.code]);

  useEffect(() => {
    if (dataResponse) {
      const sections = getHomeSections(dataResponse);
      const feedCards = dataResponse.filters ? [...dataResponse.filters] : [];

      setCustomOffers(sections.customOffers);
      setSectionSharedFlights(sections.sectionSharedFlights);
      setFeedCards(feedCards);
    }
  }, [dataResponse, currency]);

  const COMPONENT_STATUS = {
    Loading: () => (
      <div style={{ display: 'grid', placeContent: 'center' }}>
        <SpinningWheel />
      </div>
    ),
    Loaded: () => (
      <>
        {/* Shared Flights */}
        <FeedSectionGroupTitle
          title={TEXTS.SHARED_FLIGHTS}
          show={sectionSharedFlights && sectionSharedFlights.length > 0}
        />
        {sectionSharedFlights &&
          sectionSharedFlights.length > 0 &&
          sectionSharedFlights.map((section, idx) => {
            if (section.items.length > 1) {
              const items = section.items.map(item => {
                return {
                  data: mapSectionSharedFlightsItem({
                    item,
                    targetRoute: 'search',
                    currency: currency.code,
                  }),
                };
              });
              return (
                <React.Fragment key={idx}>
                  <SectionContainer
                    title={section.title}
                    subtitle={section.subtitle}
                  >
                    <SliderContainer
                      Card={SectionSharedFlightCard}
                      items={items}
                      settingsName={'small'}
                      stylesName={'normal'}
                      settings={{ slidesToScroll: 2 }}
                      hasGrid={true}
                    />
                  </SectionContainer>
                </React.Fragment>
              );
            } else {
              const props = mapsharedFlightsDetailsSection(
                section,
                currency.code
              );
              return <HomeWeeklyFlightsDetails key={idx} {...props} />;
            }
          })}

        <FeedSectionGroupTitle
          title={TEXTS.CUSTOM_OFFERS}
          show={customOffers && customOffers.length > 0}
        />
        {customOffers &&
          customOffers.length > 0 &&
          customOffers.map((section, index) => {
            const items = section.items
              .filter(item => isValidCustomOfferItem(item))
              .map(item => {
                return {
                  data: mapCustomOffersItem(item),
                };
              });

            if (!items.length) return null;

            return (
              <React.Fragment key={index}>
                <SectionContainer
                  title={section.title}
                  subtitle={section.subtitle}
                >
                  <SliderContainer
                    Card={CustomCharterOfferCard}
                    items={items}
                    settingsName={'small'}
                    stylesName={'normal'}
                    hasGrid={true}
                  />
                </SectionContainer>
              </React.Fragment>
            );
          })}
      </>
    ),
    Error: () => <span>Feed não encontrado</span>,
  };

  return (
    <Layout
      dispatchedLang={location.state && location.state.dispatchLang}
      footerStyles={{ marginBottom: '104px' }}
      pageLang={page.polylang_current_lang}
      translations={translations}
      useTranslationsPath={true}
    >
      <SEO
        {...page}
        pageLang={page.polylang_current_lang}
        translations={translations}
      />
      <Helmet>
        <meta
          name="google-site-verification"
          content="yTPiVJRSVQvH5n8YHRDCx3O24jfyXjr9w-F8uUKO41M"
        />
      </Helmet>
      <HomeHero {...sectionsProps.hero} cards={feedCards} />
      {COMPONENT_STATUS[status]()}
      <SectionRemark {...sectionsProps.remarks} />
      <HomeBanner
        title={TEXTS.AIRCRAFT_SALES_BANNER_TITLE}
        description={TEXTS.AIRCRAFT_SALES_BANNER_DESCRIPTION}
        textLink={TEXTS.KNOW_MORE}
        href="/p/aircraft-sales"
        imgSrc={AircraftSaleBanner}
        background="#fafbff"
        direction="rtl"
      />
      <HomeBanner
        title={TEXTS.AIRCRAFT_CARBON_BANNER_TITLE}
        description={TEXTS.AIRCRAFT_CARBON_BANNER_DESCRIPTION}
        textLink={TEXTS.KNOW_MORE}
        href={carbonBlogLink[lang]}
        imgSrc={AircraftCarbonImage}
        imgPosition="left"
      />
    </Layout>
  );
};

export default Feed;

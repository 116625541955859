import React from 'react';
import { Router } from '@reach/router';

import { graphql } from 'gatsby';
import Feed from '../app/Feed/Feed';

const Home = ({ data, location }) => {
  /**
   * EX 1: /      (PT-BR + LOC) Feed global or based on user location in pt-br
   * EX 2: /en    (EN-US + LOC) Feed global or based on user location in en-us
   * EX 3: /pt/mx (PT-BR + MX)  Feed for Mexico in pt-br
   * EX 4: /en/br (EN-US + BR)  Feed for Brazil in en-us
   * ...
   */
  return (
    <Router basepath="/">
      {/* feed global (pt-BR)*/}
      <Feed default data={data} location={location} />
      {/* feed global (other languages) */}
      <Feed path="/:lang" data={data} location={location} />
      {/* feed by country */}
      <Feed path="/:lang/:country" data={data} location={location} />
    </Router>
  );
};

export const pageQuery = graphql`
  query($wordpress_id: Int) {
    wordpressPage(wordpress_id: { eq: $wordpress_id }) {
      polylang_current_lang
      yoast_head
    }
    wordpressAcfPages(wordpress_id: { eq: $wordpress_id }) {
      acf {
        home_hero {
          home_hero_background {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            alt_text
          }
          home_hero_title
          home_hero_subtitle
        }
        home_section_big_numbers {
          home_big_numbers {
            home_big_number
            home_big_number_description
          }
        }
        home_section_differentials {
          home_differentials {
            home_differential_title
            home_differential_subtitle
            home_differential_description
            home_differential_image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 600, quality: 90) {
                    ...GatsbyImageSharpFluid_withWebp
                  }
                }
              }
              alt_text
            }
            home_differential_link
          }
        }
        home_section_about {
          home_about_title
          home_about_background {
            localFile {
              childImageSharp {
                fluid(maxWidth: 1920, quality: 90) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            alt_text
          }
          home_about_link
        }
        home_feed_api
      }
    }
  }
`;

export default Home;

export const groupBy = (array, key) => {
  return array.reduce((result, currentValue) => {
    (result[currentValue[key]] = result[currentValue[key]] || []).push(
      currentValue
    );
    return result;
  }, {});
};

export const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getHotSitesSections = data => {
  let sharedFlights;
  let customOffers;

  if (data.content) {
    const sections = groupBy(data.content, 'type');
    sharedFlights = sections.SharedFlights;
    customOffers = sections.CustomCharterOffers;
  }
  return { sharedFlights, customOffers };
};

export const getHomeSections = data => {
  let customOffers;
  let emptyLegs;
  let sectionSharedFlights;
  let routeSharedFlights;
  let poolFlights;

  if (data.content) {
    const sections = groupBy(data.content, 'type');
    customOffers = sections.CustomCharterOffers;
    emptyLegs = sections.EmptyLegs;
    sectionSharedFlights = sections.SectionSharedFlights;
    routeSharedFlights = sections.RouteSharedFlights;
    poolFlights = sections.PoolFlights;
  }
  return {
    customOffers,
    emptyLegs,
    sectionSharedFlights,
    routeSharedFlights,
    poolFlights,
  };
};

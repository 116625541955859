import React from 'react';
import P from 'prop-types';
import Style from './style.module.css';
import '../slick.css';
import './style.css';
import Slider from 'react-slick';

// Assets
import Img from '@components/ImageWrapper';

// Components
import SliderPrevButton from '../SliderPrevButton';
import SliderNextButton from '../SliderNextButton';
import LpForm from '../LpForm';

const SlideComponent = ({ cards }) => {
  const sliderSettings = {
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: false,
    prevArrow: <SliderPrevButton />,
    nextArrow: <SliderNextButton />,
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Slider {...sliderSettings}>
      {cards.map((card, index) => (
        <div key={index}>
          <div className={Style.card}>
            <a href="#">
              <div className={Style.cardImageWraper}>
                <img
                  src={card.imageUrl}
                  alt={card.subtitle}
                  title={card.title}
                  className={Style.cardImage}
                />
              </div>
            </a>
            <p className={Style.cardText}>{card.title}</p>
          </div>
        </div>
      ))}
    </Slider>
  );
};

SlideComponent.propTypes = {
  cards: P.array,
};

const HomeHero = ({ titleColor, title, heroImage, heroImageAlt, form }) => {
  return (
    <React.Fragment>
      <section className={[Style.hero, 'homeHero'].join(' ')}>
        <Img
          src={heroImage}
          style={{ position: 'absolute', overflow: 'inherit' }}
          className={Style.heroImage}
        />
        <div className={Style.overlay}></div>

        <div className={[Style.heroWrapper, 'container'].join(' ')}>
          <h1
            className={Style.titleColor}
            data-translation="wp:home_hero_title"
          >
            {titleColor} <br />
            <span
              className={Style.title}
              data-translation="wp:home_hero_subtitle"
            >
              {title}
            </span>
          </h1>
          <LpForm {...form} className={Style.title} />
        </div>
      </section>
    </React.Fragment>
  );
};

HomeHero.propTypes = {
  titleColor: P.string,
  title: P.string,
  heroImage: P.object,
  heroImageAlt: P.string,
  form: P.object,
};

export default HomeHero;

import React from 'react';
import * as Styled from './HomeBanner.styled';

const HomeBanner = ({
  imgSrc,
  title,
  description,
  textLink,
  href,
  background,
  direction,
  imgPosition,
}) => {
  return (
    <Styled.Container background={background} direction={direction}>
      <Styled.Img src={imgSrc} objectPosition={imgPosition} />
      <Styled.ContainerTexts>
        <h2>{title}</h2>
        <p>{description}</p>
        <a href={href} rel="noopener noreferrer">
          {textLink}
        </a>
      </Styled.ContainerTexts>
    </Styled.Container>
  );
};

export default HomeBanner;

import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  height: 410px;
  display: flex;
  flex-direction: ${props => (props.direction === 'rtl' ? 'row-reverse' : '')};
  background-color: ${props => props.background ?? 'white'};

  @media screen and (max-width: 900px) {
    height: auto;
    flex-direction: column;
  }
`;

const Img = styled.img`
  width: 45%;
  min-width: 300px;
  object-fit: cover;
  flex-shrink: 0;

  object-position: ${props => props.objectPosition ?? ''};

  @media screen and (max-width: 900px) {
    width: 100%;
    height: 280px;
  }
`;

const ContainerTexts = styled.div`
  padding: 60px;
  width: 100%;

  h2 {
    font-size: 40px;
    margin-bottom: 12px;
  }

  h2,
  a {
    font-weight: 600;
  }

  p {
    margin-bottom: 40px;
    font-size: 20px;
  }

  a {
    color: #07b7a7;
    text-decoration: none;
    font-size: 20px;
  }

  @media screen and (max-width: 900px) {
    padding: 20px;

    align-self: start;

    h2 {
      font-size: 30px;
    }

    a,
    p {
      font-size: 16px;
    }
  }
`;

export { Container, Img, ContainerTexts };

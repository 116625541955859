import styled from 'styled-components';

export const Title = styled.h2`
  margin-left: calc(var(--container-size) / 2);
  margin-bottom: 0;
  padding-bottom: 10px;
  color: #313131;
  text-transform: uppercase;
  border-bottom: 1px solid #e1e1e1;
  width: calc(100% - var(--container-size));
`;
